.slick-slider {
  .slick-prev {
    left: -17px;
    z-index: 10;
  }
  
  .slick-next {
    right: -17px;
    z-index: 10;
  }
  
  @media (min-width: 1200px) {
    .slick-prev {
      left: 0;
    }
  
    .slick-next {
      right: 0;
    }
  }
}

.light.slick-slider {
  .slick-prev:before,
  .slick-next:before {
    color: gray;
  }
}

.dark.slick-slider {
  .slick-dots li button:before {
    color: whitesmoke;
  }
  
  .slick-dots li.active button:before {
    color: whitesmoke;
  }
}
